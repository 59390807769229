import "./App.css";
// import LandingPage from "./Components/Pages/LandingPage/LandingPage";
import { lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./Components/Pages/Auth-Pages/Login/Login";
import "./reusableComps/design.scss";
import "./reusableComps/font.scss";
import SignUp from "./Components/Pages/Auth-Pages/SignUp/SignUp";
import "./reusableComps/font.scss";
import "./reusableComps/design.scss";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Onboard from "./Components/Pages/onboarding/Onboard";
import Sites from "./Components/Pages/sites/Sites";
import TermAndCond from "./Components/Pages/TermsandCond";
import About from "./Components/Pages/About/About";

const Revamp = lazy(() => import("./Components/Pages/Revamp/Revamp"));

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "instant" });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return null;
};

function App() {
  return (
    <div>
      <ToastContainer />
      <ScrollToTop />
      <Routes>
        <Route index element={<Revamp />} />
        <Route path="/onboarding" element={<Onboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/sites" element={<Sites />} />
        <Route path="/terms" element={<TermAndCond />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </div>
  );
}

export default App;
