import React from "react";
import heroimg from "@assets/Images/JellositeLogo-04.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { BiLogoFacebook } from "react-icons/bi";
import "./Footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="footer-top">
        <div className="left-side flex flex-col gap-9 w-fit">
          <img src={heroimg} alt={heroimg} className="w-32" />

          <span className="text-[#19182580] w-[450px] leading-[30px]">
          JelloSite empowers individuals and businesses across the country, providing the tools they need to amplify their presence and build trust online 
          </span>
          <div className="flex gap-4 items-center justify-start">
            <span className="h-8 w-8 rounded-full bg-[#7e57c2] flex items-center justify-center">
              <BiLogoFacebook color="white" size={20} />
            </span>
            <span className="h-8 w-8 rounded-full bg-[#7e57c2] flex items-center justify-center">
              <FaTwitter color="white" size={20} />
            </span>
            <span className="h-8 w-8 rounded-full bg-[#7e57c2] flex items-center justify-center">
              <FaInstagram color="white" size={20} />
            </span>
          </div>
        </div>
        <div className="right-side">
          <section>
            <header>COMPANY</header>
            <ul>
              <li>
                <a href="/about" className="no-underline text-black">
                  About
                </a>
              </li>
              <li>Affiliates</li>
              <li>
                <a href="/#pricing" className="no-underline text-black">
                  Pricing
                </a>
              </li>
              <li>
                <a href="/terms" className="no-underline text-black">
                  Terms of service
                </a>
              </li>
            </ul>
          </section>
          <section>
            <header>SUPPORT</header>
            <ul>
              <li>Merchant Support</li>
              <li>FAQ's</li>
              <li>Help Center</li>
            </ul>
          </section>
          <section>
            <header>CONTACT US</header>
            <ul>
              <li>
                <a href="mailto:info@jellosite.com" className="no-underline text-black">info@jellosite.com</a>
              </li>
              <li>Abuja, Nigeria</li>
            </ul>
          </section>
          {/* <section>
            <header>STAY UP TO DATE</header>
            <span>Subscribe to Our newsletter</span>
            <div className="subscribe">
              <input type="email" placeholder=" Email" />
              <FontAwesomeIcon
                icon={faArrowRight}
                color="#5729D4"
                cursor="pointer"
              />
            </div>
          </section>
          <section>
            <header>PRODUCTS</header>
            <ul>
              <li>Shop</li>
              <li>Shop pay</li>
              <li>Commerce Companies</li>
            </ul>
          </section> */}
        </div>
      </div>
      <hr />
      <span className="copyrights">© Copyright Jellosite Inc. 2024</span>
    </footer>
  );
};

export default Footer;
