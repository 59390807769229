import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: `${import.meta.env.VITE_API_URL}/api`,
});

export const generalApiSlice = createApi({
  baseQuery,
  endpoints: (builder) => ({
    getSite: builder.query({
      query: (access) => ({
        url: "/sites",
        method: "GET",
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    checkSite: builder.query({
      query: ({ access, slug }) => ({
        url: `/sites/available/${slug}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
  }),
});

export const { useGetSiteQuery, useLazyCheckSiteQuery, useLoginMutation } =
  generalApiSlice;
