import { useState } from "react";
import Logo from "@assets/Images/JellositeLogoandWordmark05.png";
import "../Navbar/Navbar.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  function handleToggle() {
    setToggle(!toggle);
  }

  return (
    <div className="navbar">
      <a href="/#" className="no-underline">
        <img src={Logo} alt={Logo} className="h-10" />
      </a>
      <FontAwesomeIcon icon={toggle ? faTimes : faBars} onClick={handleToggle} cursor="pointer" className="nav-toggle" />
      <div className={toggle ? "nav-items" : "null"}>
        <ul>
          <li>
            <a href="/#" className="no-underline text-black">
              Home
            </a>
          </li>
          <li>
            <a className="no-underline text-black" href="/#pricing">
              Pricing
            </a>
          </li>
          <li>
            <a className="no-underline text-black" href="/about">
              About
            </a>
          </li>
        </ul>
        <div className="auth-btns flex gap-10 items-center justify-center">
          <Link to="/login" className="no-underline">
            <ul>
              <li className="text-[#7e57c2]">Login</li>
            </ul>
          </Link>
          <Link to="/signup" className="link">
            <button>Try for Free</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
