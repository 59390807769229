import Footer from "../../Layouts/Footer/Footer";
import Navbar from "../../Layouts/Navbar/Navbar";
import founder1 from "../../../assets/Images/founder1.png";
import founder2 from "../../../assets/Images/founder2.jpg";
import team1 from "../../../assets/Images/team (1).png";
import team2 from "../../../assets/Images/team (2).png";
import team3 from "../../../assets/Images/team (3).png";
import team4 from "../../../assets/Images/team (4).png";
import team5 from "../../../assets/Images/team (5).png";
import team6 from "../../../assets/Images/team (6).png";
import heroobj from "../../../assets/Images/heroobj.svg";
import obj from "../../../assets/Images/obj.svg";

import { BiCalendar, BiSearch, BiSmile } from "react-icons/bi";
const About = () => {
  return (
    <div className=" overflow-x-hidden">
      <Navbar />
      <img
        src={heroobj}
        alt={heroobj}
        className="heroobj bounce absolute top-[700px] right-0 opacity-15"
      />
      <img
        src={obj}
        alt={obj}
        className="heroobj absolute bounce top-[170px] left-0 opacity-15"
      />
      <div className="relative">
        <div className="w-full  gap-3 py-16 text-[#333333] flex flex-col items-center">
          <h1>About Us</h1>
          <p>Learn what you can about us</p>
        </div>
        <div className="relative px-5 my-5 md:my-20 md:flex-row items-center w-full justify-center !gap-16 flex-col-reverse flex h-fit ">
          <div className="founders ml-[9%] pl-4 sm:left-0 relative justify-start w-fit flex">
            <div className="relative top-20">
              <img
                className="relative p-2 bg-white w-[180px] lg:w-[250px] aspect-[1/1] object-contain z-10"
                src={founder1}
                alt=""
              />
              <span>
                <h4 className="pl-2">Davies Nzan</h4>
                <p className="pl-2">Founder</p>
              </span>
            </div>
            <div className="relative right-[45px]">
              <img
                className=" w-[180px] lg:w-[250px] aspect-[1/1] object-contain"
                src={founder2}
                alt=""
              />
              <span className="flex flex-col items-end pr-4">
                <h4>David Udoh</h4>
                <p>Co-Founder</p>
              </span>
            </div>
          </div>
          <div className="vision relative flex flex-col items-center md:items-start md:text-left gap-3 text-center w-full md:w-1/3 ">
            <span className="text-2xl font-medium text-[#F85E9F]">Vision</span>
            <h2 className="font-semibold">
              Amplifying individual businesses, one person at a time.
            </h2>
            <p>
              Jellosite empowers one person businesses all over the country to
              make more money by giving them the tools to not only amplify
              themselves, but build trust and have better work life balance.
              <br />
              <br />
              We do this with the help of a transparent, talented and
              hardworking team.
            </p>
          </div>
        </div>
        <div className="how mt-32 md:mt-44 gap-10 md:flex-row flex-col  justify-center flex px-5">
          <div className="text-center md:text-left flex flex-col w-full md:w-1/3">
            <span className="text-2xl font-medium text-[#F85E9F]">
              “Honestly, no one does what we do”
            </span>
            {/* <h2>Lorem ipsum dolor sit.</h2> */}
            <p className="mt-3">
              Unlocking the potential of African SMEs by providing a platform to
              showcase their products and services to the world.
            </p>
          </div>
          <div className="list flex flex-col gap-6 md:w-2/5 w-full">
            <div className="flex items-center  gap-3 rounded-xl bg-slate-100  p-5">
                <span className="bg-[#F6F1FF] items-center flex h-full">
                <BiCalendar />

              </span>
              <span className="flex flex-col gap-4">
                {/* <h4>Browse and select</h4> */}
                <p>Being on your phone 24/7 so you don’t lose clients</p>
              </span>
            </div>
            <div className="flex items-center  gap-3 rounded-xl bg-slate-100  p-5">
              <span className="bg-[#F6F1FF] items-center flex h-full">
                <BiSearch />
              </span>
              <span>
                {/* <h4>Lorem, ipsum.</h4> */}
                <p>
                  Losing customers because they don’t know all the
                  products/services you offer and are not patient enough to hear
                  back from you.
                </p>
              </span>
            </div>
            <div className="flex items-center  gap-3 rounded-xl bg-slate-100  p-5">
              <span className="bg-[#F6F1FF] items-center flex h-full">
                <BiSmile />
              </span>
              <span>
                {/* <h4>Lorem, ipsum.</h4> */}
                <p>
                  Losing customers due to trust issues when texting with them
                </p>
              </span>
            </div>
          </div>
        </div>
        <div className="grid justify-center items-center px-5  grid-cols-2 gap-5 md:px-14 my-10 lg:grid-cols-4">
          <div className="flex flex-col  gap-3 justify-center items-center md:text-left md:items-start text-center justify-items-center col-span-2 md:w-3/5">
            <h2>Meet the Jellosite team</h2>
            <p>
              We are a team of freelancers who not only know the struggle of
              marketing yourself to clients, but also share a drive and passion
              for providing access to a global audience for other one person
              businesses.
            </p>
            {/* <button className="bg-[#7E57C2] text-white w-fit px-10 py-2">
              View All
            </button> */}
          </div>
          {/* <img className="w-[95%]" src={team1} alt="" />
          <img className="w-[95%]" src={team2} alt="" />
          <img className="w-[95%]" src={team3} alt="" />
          <img className="w-[95%]" src={team4} alt="" />
          <img className="w-[95%]" src={team5} alt="" />
          <img className="w-[95%]" src={team6} alt="" /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
